<template>
    <div>
        <h2 class="align-center">Export Type</h2>
        <div class="row pad-big">
            <div
                class="col-12 badge clickable marg-small"
                :class="activeTab === 'user' ? 'bg-success' : 'bg-secondary'"
                @click="switchNav('user')"
            >
                <span>User Data</span>
            </div>
            <div
                class="col-12 badge clickable marg-small"
                :class="activeTab === 'book' ? 'bg-success' : 'bg-secondary'"
                @click="switchNav('book')"
            >
                <span>Book Data</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Nav",
    props: [
        'active-tab'
    ],
    methods: {
        switchNav(val) {
            this.$emit('change-active-tab', val)
        }
    }
}
</script>
