<template>
<div class="row">
    <div id="side" class="col-3 d-none d-md-block"></div>
    <div id="main" class="col-12 col-md-9 marg-big">
        <div class="book-card pad-big">
            <h2>Lessons Cleaner</h2>
            <div class="marg-big">
                <button class="btn bg-primary" @click="cleanLessons()">Mark Lessons as Known</button>
                <p>This will mark lesson items as known, allowing you to personalize future vocabulary list exports even without engaging with the website.</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Cleaner',
    props: {
        dat: {
            default() {
                return []
            }
        },
    },
    methods: {
        cleanLessons() {
            this.$emit('clean-lessons')
        }
    }
}
</script>
