<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <div
                    v-if="mode === 'vocab'"
                    class="col col-md-12 module"
                >
                    <h2>Vocabulary Fields</h2>
                    <div class="row marg-small">
                        <label class="col-4">Entry Style</label>
                        <select class="col-6" v-model="vocabOptions.vocabularyStyle">
                            <option :value="0">Most Common</option>
                            <option :value="1">Mash all</option>
                        </select>
                    </div>
                    <div class="row marg-small"
                        v-for="(val, keys, index) in vocabOptions.iterables"
                        :key="index+'i'"
                    >
                        <label class="col-4">{{capitalize(keys)}}</label>
                        <select class="col-6" v-model="vocabOptions.iterables[keys]">
                            <option :value="false">Off</option>
                            <option :value="true">On</option>
                        </select>
                    </div>
                </div>
                <div
                    v-else
                    class="col col-md-12 module"
                >
                    <h2>Kanji Fields</h2>
                    <div class="row marg-small"
                        v-for="(val, keys, index) in kanjiOptions"
                        :key="index+'i'"
                    >
                        <label class="col-4">{{capitalize(keys)}}</label>
                        <select class="col-6" v-model="kanjiOptions[keys]">
                            <option :value="false">Off</option>
                            <option :value="true">On</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9 marg-big">
            <div class="book-card pad-big">
                <h2 class="marg-big">{{capitalize(mode)}}</h2>
                <div>Below is a preview of what the fields will look like for the finalized csv.</div>
                <div><i class="fas fa-exclamation-triangle c-red"></i> Displaying a maximum of {{maxRows}} rows</div>
                <!-- table -->
                <div class="table-holder">
                    <table class="table table-striped marg-big">
                        <thead>
                            <tr>
                                <th>Row #</th>
                                <th
                                    v-for="(items, indx) in columns"
                                    :key="indx+'c'"
                                    scope="col"
                                >{{items}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row, indx) in preview.slice(0, maxRows)"
                                :key="indx+'a'"
                            >
                                <th scope="row">{{indx}}</th>
                                <td
                                    v-for="(items, colN) in row"
                                    :key="colN+'r'"
                                >{{items}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="marg-big">
                    <button class="btn bg-primary" @click="finalize(dat)">Generate Full File</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertArrayToCSV, converter } from 'convert-array-to-csv'

export default {
    name: 'fieldPreviewBook',
    props: {
        dat: {
            default() {
                return []
            }
        },
        mode: {
            default() {
                return 'vocab'
            }
        },
        iterables: {
            default() {
                return {
                    freq: true,
                    id: true,
                    match: true
                }
            }
        }
    },
    methods: {
        capitalize(str) {
            return `${str.charAt(0).toUpperCase()}${str.slice(1).replace(/_/g, ' ')}`
        },
        generateRows(opts) {
            const { dat, iterables, style } = opts
            const arr = []
            const genRow = (card) => {
                const obj = {}
                obj.word = card.word
                const cardIterables = Object.keys(iterables)
                for (let i = 0; i < cardIterables.length; i += 1) {
                    const currentKey = cardIterables[i]
                    if (currentKey in card && iterables[currentKey]) obj[currentKey] = card[currentKey]
                }
                return obj
            }
            const genEntry = (obj, sty, entry) => {
                if (sty === 2 || sty === 0) {
                    //  separate rows, so only grab from one
/*                     const def = entry.subentries.map((subentry) => subentry.definition.join(', '))
                    obj.definition = def.join('; ') */
                    obj.definition = entry.definition
                    obj.reading = entry.reading
                }
                const a = []
                return obj
            }
            //  mash all entries into definition format of: (reading) entry1, entry2; (reading2) entry1
            const mash = (obj, row) => {
                let lastReading = ""
                const readings = []
                const defs = []
                const singularReading = row.entries.length === 1
                row.entries.forEach((i) => {
                    let defText = ""
                    if (lastReading !== i.reading) {
                        lastReading = i.reading
                        readings.push(i.reading)
                        if (!singularReading) defText += `(${i.reading}) `
                    }
                    defText += `${i.definition}`
                    defs.push(defText)
                })
                obj.reading = readings.join(', ')
                obj.definition = defs.join('; ')
            }
            dat.forEach((row) => {
                //  do one loop unless style type is 2
                const loops = [0, 1].includes(style) ? 1 : row.entries.length
                console.log('row', row, loops)
                //  mash
                //  normal
                if (style === 1) {
                    const obj = genRow(row)
                    mash(obj, row)
                    arr.push(obj)
                } else {
                    for (let i = 0; i < loops; i += 1) {
                        const obj = genRow(row)
                        genEntry(obj, style, row.entries[i])
                        arr.push(obj)
                    }
                }
            })
            return arr
        },
        finalize(dat) {
            const style = this.mode === 'vocab'
                            ? this.vocabOptions.vocabularyStyle
                            : 0
            const opts = this.mode === 'vocab'
                            ? this.vocabOptions.iterables
                            : this.kanjiOptions
            const rows = this.generateRows({ dat, iterables: opts, style })
            const generateLink = (csvContent) => {
                const downloadLink = document.createElement("a");
                const blob = new Blob(["\ufeff", csvContent]);
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "data.csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
            //  body
            const csvFromArrayOfObjects = convertArrayToCSV(rows, {
                separator: this.generatorOptions.separator
            });
            generateLink(csvFromArrayOfObjects)
        }
    },
    computed: {
        columns() {
            if (this.dat && this.preview[0]) return Object.keys(this.preview[0])
            return {}
        },
        preview(dat) {
            if (this.mode === 'vocab') {
                return this.generateRows(
                    {
                    dat: this.dat.slice(0, this.maxRows),
                    iterables: this.vocabOptions.iterables,
                    style: this.vocabOptions.vocabularyStyle
                    }
                )
            }
            return []
            /* if (this.mode === 'kanji') return this.generateRows(this.dat.slice(0, this.maxRows), this.kanjiOptions, 0) */
        }
    },
    data() {
        return {
            maxRows: 100,
            generatorOptions: {
                separator: '\t'
            },
            vocabOptions: {
                /* 0 = simplified (single) [reading, definition]
                1 = mash: combine reading and definition [(reading): definition]
                2 = separate rows for entries by reading [reading, definition]
                */
                vocabularyStyle: 0,
                iterables: {
                    //  active_readings_only: true, //   remove any entries that dont match active readings
                    //  active_readings: true,
                },
            },
        }
    },
    created() {
        if (Object.keys(this.iterables).length > 0) this.vocabOptions.iterables = this.iterables
    }
}
</script>

<style lang="sass" scoped>
.thing-bod
    overflow-x: auto
.table-holder
    font-size: .8rem
    max-height: 300px
    overflow-y: auto
table.table
    th
        min-width: 8em
    .bg-danger, .bg-success, .bg-primary, .bg-purple
        color: white
</style>
