<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <Selection
                    class="col col-12 module align-center pad-big marg-big"
                    :books="books"
                    :msg="activeBook ? activeBook.title : 'Select a book'"
                    v-on:book-changed="setBook($event)"
                />
                <div
                    class="col col-md-12 module"
                >
                    <h2>Active Item Settings</h2>
                    <div class="marg-big row">
                        <label class="col-4">Freq</label>
                        <input
                            class="col-6"
                            type="number"
                            min="0"
                            v-model.number="activeOpts.freq"
                        />
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">WK</label>
                        <input
                            class="col-6"
                            type="number"
                            min="0"
                            max="60"
                            v-model.number="activeOpts.wk"
                        />
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">Filter Known Words</label>
                        <select class="col-6" disabled>
                            <option>Always On</option>
                        </select>
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">Exact Matches Only</label>
                        <select class="col-6" disabled>
                            <option>Always On</option>
                        </select>
                    </div>
                </div>
                <div class="col col-md-12 module">
                    <h2>Generate</h2>
                    <button class="col-12 btn bg-primary" @click="generateSet()">Generate Set</button>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9 marg-big">
            <div class="book-card pad-big">
                <h2 v-if="dataLen && dataLen === 0" class="marg-big">Click a book to generate data</h2>
                <h2 v-else class="marg-big">{{dataLen}} words generated</h2>
                <div><i class="fas fa-exclamation-triangle c-red"></i> The exporter currently only exports exact word matches.</div>
            </div>
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import Selection from '../../Vocabulary/Select/Select.vue'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C
export default {
    name: 'Book',
    components: {
        Selection
    },
    props: {
        dataLen: {
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            books: [],
            activeBook: null,
            activeOpts: {
                freq: 0,
                wk: 0,
            }
        }
    },
    methods: {
        getBooks() {
            return new Promise((resolve, reject) => {
                UseAPI('/get/booksWithFlags', { method: "POST" })
                .then((dat) => resolve(dat))
                .catch((dat) => {
                    HandleRequestFail(dat)
                    reject()
                })
            })
        },
        addBooks() {
            //  add received book api request to component list of books
            this.getBooks()
            .then((result) => {
                result.items.forEach((item) => this.books.push(item))
            })
            .catch((result) => {
                console.warn('Issue fetching books', result)
            })
        },
        setBook(bookId) {
            //  set active book
            const activeBook = this.books.find((aBook) => parseInt(aBook.id, 10) === parseInt(bookId, 10))
            this.activeBook = activeBook
        },
        verifyGenerate() {
            if (!('vocab_list' in this.activeBook)) return false
            return true
        },
        generateSet() {
            const opts = { list: this.activeBook.vocab_list, ...this.activeOpts }
            if (!this.verifyGenerate(opts)) return
            this.$emit('generate-set', opts)
        },
    },
    async created() {
        await this.addBooks()
    }
}
</script>
