<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <div
                    v-if="mode === 'vocab'"
                    class="col col-md-12 module"
                >
                    <h2>Vocabulary Fields</h2>
                    <div class="row marg-small">
                        <label class="col-4">Entry Style</label>
                        <select class="col-6" v-model="vocabOptions.vocabularyStyle">
                            <option :value="0">Most Common</option>
                            <option :value="2">Separate Rows</option>
                        </select>
                    </div>
                    <div class="row marg-small"
                        v-for="(val, keys, index) in vocabOptions.iterables"
                        :key="index+'i'"
                    >
                        <label class="col-4">{{capitalize(keys)}}</label>
                        <select class="col-6" v-model="vocabOptions.iterables[keys]">
                            <option :value="false">Off</option>
                            <option :value="true">On</option>
                        </select>
                    </div>
                </div>
                <div
                    v-else
                    class="col col-md-12 module"
                >
                    <h2>Kanji Fields</h2>
                    <div class="row marg-small"
                        v-for="(val, keys, index) in kanjiOptions"
                        :key="index+'i'"
                    >
                        <label class="col-4">{{capitalize(keys)}}</label>
                        <select class="col-6" v-model="kanjiOptions[keys]">
                            <option :value="false">Off</option>
                            <option :value="true">On</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9 marg-big">
            <div class="book-card pad-big">
                <h2 class="marg-big">{{capitalize(mode)}}</h2>
                <div>Below is a preview of what the fields will look like for the finalized csv.</div>
                <div><i class="fas fa-exclamation-triangle c-red"></i> Displaying a maximum of {{maxRows}} rows</div>
                <!-- table -->
                <div class="table-holder">
                    <table class="table table-striped marg-big">
                        <thead>
                            <tr>
                                <th>Row #</th>
                                <th
                                    v-for="(items, indx) in columns"
                                    :key="indx+'c'"
                                    scope="col"
                                >{{items}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(row, indx) in preview.slice(0, maxRows)"
                                :key="indx+'a'"
                            >
                                <th scope="row">{{indx}}</th>
                                <td
                                    v-for="(items, colN) in row"
                                    :key="colN+'r'"
                                >{{items}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="marg-big">
                    <button class="btn bg-primary" @click="finalize(dat)">Generate Full File</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { convertArrayToCSV, converter } from 'convert-array-to-csv'
/* eslint-disable camelcase */
export default {
    name: 'FieldPreview',
    props: {
        mode: {
            default() {
                return 'vocab'
            }
        },
        dat: {
            default() {
                return []
            }
        },
    },
    methods: {
        capitalize(str) {
            return `${str.charAt(0).toUpperCase()}${str.slice(1).replace(/_/g, ' ')}`
        },
        generateRows(dat, options, style = 2) {
            const arr = []
            const {
                //  active_readings_only = true,
                //  active_readings,
                notes,
                queue_state,
                card_type,
                synonyms,
                source
            } = options
            const genRow = (card) => {
                const obj = {}
                //  obvious stuff
                obj.word = card.word
                if (notes) obj.notes = card.notes
                if (queue_state) obj.queue_state = card.queue_state
                if (card_type) obj.card_type = card.card_type
                if (synonyms) obj.synonyms = card.synonyms
                if (source) obj.source = card.source
                return obj
            }
            const genEntry = (obj, sty, entry) => {
                if (sty === 2 || sty === 0) {
                    //  separate rows, so only grab from one
                    const def = entry.subentries.map((subentry) => subentry.definition.join(', '))
                    obj.definition = def.join('; ')
                    obj.reading = entry.reading
                }
                return obj
            }
            dat.forEach((row) => {
                //  do one loop unless style type is 2
                const loops = style === 2 ? row.entries.length : 1
                console.log('row', row, loops)
                for (let i = 0; i < loops; i += 1) {
                    const obj = genRow(row)
                    genEntry(obj, style, row.entries[i])
                    arr.push(obj)
                }
            })
            return arr
        },
        finalize(dat) {
            const style = this.mode === 'vocab'
                            ? this.vocabOptions.vocabularyStyle
                            : 0
            const opts = this.mode === 'vocab'
                            ? this.vocabOptions.iterables
                            : this.kanjiOptions
            const rows = this.generateRows(dat, opts, style)
            const generateLink = (csvContent) => {
                const downloadLink = document.createElement("a");
                const blob = new Blob(["\ufeff", csvContent]);
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "data.csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
            //  body
            const csvFromArrayOfObjects = convertArrayToCSV(rows, {
                separator: this.generatorOptions.separator
            });
            generateLink(csvFromArrayOfObjects)
        }
    },
    computed: {
        columns() {
            if (this.dat && this.preview[0]) return Object.keys(this.preview[0])
            return {}
        },
        preview(dat) {
            if (this.mode === 'vocab') return this.generateRows(this.dat.slice(0, this.maxRows), this.vocabOptions.iterables, this.vocabOptions.vocabularyStyle)
            if (this.mode === 'kanji') return this.generateRows(this.dat.slice(0, this.maxRows), this.kanjiOptions, 0)
            //  Vue.$forceRefresh()
            return []
        }
    },
    data() {
        return {
            maxRows: 100,
            generatorOptions: {
                separator: '\t'
            },
            vocabOptions: {
                vocabularyStyle: 0, //    0 = simplified [reading, definition]  1 = combine reading and definition [(reading): definition]  2 = separate rows for entries by reading [reading, definition]
                iterables: {
                    //  active_readings_only: true, //   remove any entries that dont match active readings
                    //  active_readings: true,
                    notes: true,
                    queue_state: true,
                    card_type: true,
                    synonyms: true,
                    source: true
                },
            },
            kanjiOptions: {
                //  kanjiStyle: 0,
                notes: true,
                queue_state: true,
                card_type: true,
                synonyms: true,
                source: true
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.thing-bod
    overflow-x: auto
.table-holder
    font-size: .8rem
    max-height: 300px
    overflow-y: auto
table.table
    th
        min-width: 8em
    .bg-danger, .bg-success, .bg-primary, .bg-purple
        color: white
</style>
