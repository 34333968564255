<template>
    <div id="importer" class="container-fluid">
        <div class="col-12 col-lg-11 col-xl-10 mx-auto spacer">
            <Nav class="col-12 book-card pad-big marg-big" :activeTab="activeTab"
                v-on:change-active-tab="activeTab = $event" />
            <User v-if="activeTab === 'user'" :dataLen="userData ? userData.length : userData"
                v-on:generate-set="generateUserData($event)" />
            <Book v-if="activeTab === 'book'" :dataLen="userData ? userData.length : userData"
                v-on:generate-set="generateBookData($event)" />
            <Field-Preview v-if="activeTab === 'user' && userData" :dat="vocab" :mode="'vocab'" />
            <Field-Preview v-if="activeTab === 'user' && userData" :dat="kanji" :mode="'kanji'" />
            <Field-Preview-Book v-if="activeTab === 'book' && userData" :dat="vocab"
                :iterables="{ freq: true, id: true, match: true }" />
            <Cleaner v-if="activeTab === 'user' && userData" :dat="lessonsList" v-on:clean-lessons="cleanLessons($event)" />
        </div>
    </div>
</template>

<script>
import C from '@/assets/common'
import Nav from './Nav.vue'
import User from './User.vue'
import Book from './Book.vue'
import FieldPreview from './FieldPreview.vue'
import FieldPreviewBook from './FieldPreviewBook.vue'
import Cleaner from './Cleaner.vue'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C

export default {
    name: 'Export',
    components: {
        Nav,
        User,
        Book,
        FieldPreview,
        FieldPreviewBook,
        Cleaner,
    },
    data() {
        return {
            userData: null,
            activeTab: null,
            awaitingResponse: false
        }
    },
    computed: {
        kanji() {
            if (!this.userData) return []
            return this.userData.filter((i) => i.card_type === 'k')
        },
        vocab() {
            if (!this.userData) return []
            return this.userData.filter((i) => i.card_type === 'v')
        },
        lessonsList() {
            //  for Cleaner
            return this.userData.filter((i) => i.queue_state === 0)
                .map((item) => item.card)
        }
    },
    methods: {
        generateUserData(opts) {
            //  TO DO: CREATE ROUTE ON /GET/
            //  RETRIEVE SEVERAL LISTS OF ITEMS BASED ON WHAT'S ON IN THE OPTS PART OF THE BODY
            if (this.awaitingResponse) {
                SendUserAlert('Still waiting for server response', 'alert-warning')
                return false
            }
            return UseAPI('/get/export-user-data', { method: "POST", body: JSON.stringify(opts) })
                .then((dat) => {
                    this.userData = dat.items
                })
                .catch((dat) => {
                    console.warn('Error with /create/import-known/')
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                    this.serverResponse = dat
                })
                .then(() => {
                    this.awaitingResponse = false
                })
        },
        cleanLessons() {
            if (this.awaitingResponse) {
                SendUserAlert('Still waiting for server response', 'alert-warning')
                return false
            }
            return UseAPI('/delete/trim-lessons-known', { method: "DELETE" })
                .then((dat) => {
                    SendUserAlert('Marked words as known')
                })
                .catch((dat) => {
                    console.warn('Error with marking words as known. Check if operation was completed by going to lessons.')
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                    this.serverResponse = dat
                })
                .then(() => {
                    this.awaitingResponse = false
                })
        },
        generateBookData(opts) {
            const { list, wk = 0, freq = 0 } = opts
            console.log('opts', opts)
            //  list, wk, freq
            //  remember to use parse int on the server side
            if (this.awaitingResponse) {
                SendUserAlert('Still waiting for server response', 'alert-warning')
                return false
            }
            return UseAPI('/get/export-list', { method: "POST", body: JSON.stringify({ list, wk, freq, }) })
                .then((dat) => {
                    //  modify data to work with computed props
                    dat.items.forEach((i) => {
                        i.card_type = 'v'
                        i.entries.forEach((anEntry) => {
                            anEntry.definition = anEntry.definition.join(', ')
                        })
                    })
                    this.userData = dat.items
                })
                .catch((dat) => {
                    console.warn('Error with /create/import-known/')
                    HandleRequestFail(dat)
                    console.warn(dat.msg)
                    console.warn(JSON.stringify(dat.e))
                    this.serverResponse = dat
                })
                .then(() => {
                    this.awaitingResponse = false
                })
        }
    }
}
</script>
