<template>
<div>
    <div class="d-flex flex-wrap justify-between">
        <h2 class="align-left">{{msg}}</h2>
        <div style="font-size:1.5rem;">
            <i class="fas fa-film clickable" v-tippy content="Toggle anime" :class="showing.includes('a') ? 'c-blue' : '' " @click="toggleMedium('a')"></i>
            <i class="fas fa-book-open clickable" v-tippy content="Toggle books" :class="showing.includes('b') ? 'c-blue' : '' " @click="toggleMedium('b')"></i>
            <i class="fas fa-video clickable" v-tippy content="Toggle dramas" :class="showing.includes('d') ? 'c-blue' : '' " @click="toggleMedium('d')"></i>
            <i class="fas fa-gamepad clickable" v-tippy content="Toggle videogames" :class="showing.includes('v') ? 'c-blue' : '' " @click="toggleMedium('v')"></i>
            <i class="fas fa-archive clickable" v-tippy content="Toggle others" :class="showing.includes('o') ? 'c-blue' : '' " @click="toggleMedium('o')"></i>
        </div>
    </div>
    <div class="i-group flat w-100 mx-auto">
        <div>
            <i class="fas fa-search"></i>
        </div>
        <select
            v-model="selectedBook"
            :disabled="booksToShow.length === 0"
            class="w-100"
            style="min-height:2.5em"
        >
            <option value="" selected class="d-none">Select a list...</option>
            <option
            v-if="filteredBooks.favorites.length > 0"
            :disabled="true"
            class="c-red align-center"
            >Favorites</option>
            <option
                v-for="(items, indx) in filteredBooks.favorites"
                :key="indx+'b'"
                :value="items.id"
                :disabled="items.until_release"
            >{{`${showing.length > 1 ? mediumToString(items.medium) + ' | ' : ''}${items.title}`}}</option>
            <option
                v-if="filteredBooks.favorites.length > 0"
                :disabled="true"
                class="c-red align-center"
            >Lists</option>
            <option
                v-for="(items, indx) in booksToShow"
                :key="indx+'a'"
                :value="items.id"
                :disabled="items.until_release"
            >{{`${showing.length > 1 ? mediumToString(items.medium) + ' | ' : ''}${items.title}`}}</option>
        </select>
    </div>
</div>
</template>

<script>
export default {
    name: 'Selection',
    props: {
        books: {
            default() {
                return []
            }
        },
        msg: {
            default() {
                return 'No msg'
            }
        }
    },
    data() {
        return {
            showing: ['a', 'b', 'd', 'v', 'o'],
            selectedBook: '',
            flags: {
                firstMediumToggled: false,
            }
        }
    },
    computed: {
        filteredBooks() {
            const { books } = this
            return ({
                favorites: books.filter((i) => i.flag && i.flag.split(',').includes('wtr')),
                anime: books.filter((i) => i.medium === 'a'),
                books: books.filter((i) => i.medium === 'b'),
                games: books.filter((i) => i.medium === 'v'),
                uncategorized: books.filter((i) => i.medium === 'o'),
                dramas: books.filter((i) => i.medium === 'd')
            })
        },
        booksToShow() {
            const { showing } = this
            //  const favorites = this.filteredBooks.favorites
            const anime = showing.includes('a') ? this.filteredBooks.anime : []
            const books = showing.includes('b') ? this.filteredBooks.books : []
            const games = showing.includes('v') ? this.filteredBooks.games : []
            const uncategorized = showing.includes('o') ? this.filteredBooks.uncategorized : []
            const dramas = showing.includes('d') ? this.filteredBooks.dramas : []
            return [...anime, ...books, ...games, ...uncategorized, ...dramas]
        }
    },
    watch: {
        selectedBook(n) {
            if (!this.selectedBook) return false
            const success = this.broadcastBookChange(n)
            if (success) this.selectedBook = ''
            return true
        },
    },
    methods: {
        broadcastBookChange(id) {
            this.$emit('book-changed', id)
            return true
        },
        mediumToString(str, capitalize = true) {
            if (str === 'b') return capitalize ? 'Book' : 'book'
            if (str === 'a') return capitalize ? 'Anime' : 'anime'
            if (str === 'v') return capitalize ? 'Game' : 'game'
            if (str === 'd') return capitalize ? 'Drama' : 'drama'
            return capitalize ? 'Other' : 'other'
        },
        toggleMedium(val) {
            if (this.flags.firstMediumToggled) {
                this.showing.includes(val)
                ? this.showing.splice(this.showing.findIndex((i) => i === val), 1)
                : this.showing.push(val)
                return
            }
            this.showing = [val]
            this.flags.firstMediumToggled = true
        }
    }
}
</script>

<style lang="sass" scoped>
i
    padding: 0 .15em!important
.i-group
    select
        border: 0
        border-left: 2px solid #eee
</style>
