<template>
    <div class="row">
        <div id="side" class="col-12 col-md-3">
            <div id="book_sidebar" class="row">
                <div class="col col-md-12 module">
                    <h2>Retrieval Settings</h2>
                    <!--boolean-->
                    <div
                        class="marg-big row"
                    >
                        <label class="col-4">Lessons</label>
                        <i class="fa-2x col"
                        @click="generateOpts.lessons ? generateOpts.lessons = false : generateOpts.lessons = true"
                        :class="generateOpts.lessons ? 'fas fa-toggle-on c-green' : 'fas fa-toggle-off'"
                        ></i>
                    </div>
                    <div
                        class="marg-big row"
                    >
                        <label class="col-4">Known</label>
                        <i class="fa-2x col"
                        @click="generateOpts.known ? generateOpts.known = false : generateOpts.known = true"
                        :class="generateOpts.known ? 'fas fa-toggle-on c-green' : 'fas fa-toggle-off'"
                        ></i>
                    </div>
                    <div
                        class="marg-big row"
                    >
                        <label class="col-4">Active</label>
                        <i class="fa-2x col"
                        @click="generateOpts.active ? generateOpts.active = false : generateOpts.active = true"
                        :class="generateOpts.active ? 'fas fa-toggle-on c-green' : 'fas fa-toggle-off'"
                        ></i>
                    </div>
                </div>
                <div
                    v-if="generateOpts.active"
                    class="col col-md-12 module"
                >
                    <h2>Active Item Settings</h2>
                    <div class="marg-big row">
                        <label class="col-4">Low</label>
                        <input
                            class="col-6"
                            v-model="activeOpts.activeLow"
                            type="number"
                            min="1"
                        />
                    </div>
                    <div class="marg-big row">
                        <label class="col-4">High</label>
                        <input
                            class="col-6"
                            v-model="activeOpts.activeHigh"
                            type="number"
                            max="10"
                        />
                    </div>
                </div>
                <div class="col col-md-12 module">
                    <h2>Generate</h2>
                    <button class="col-12 btn bg-primary" @click="generate()">Generate Set</button>
                </div>
            </div>
        </div>
        <div id="main" class="col-12 col-md-9 marg-big">
            <div class="book-card pad-big">
                <div v-if="!dataLen || dataLen.length === 0">
                    <h2 class="marg-big">No Data</h2>
                    <div>Use the <strong>generate</strong> button to create a list.</div>
                </div>
                <div>
                    <h2 v-if="dataLen > 0">Retrieved {{dataLen}} cards.</h2>
                    <p>Use the regions below to generate exportable files.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'User',
    props: [
        'dataLen'
    ],
    methods: {
        verifyGenerate(opts) {
            const valid = opts.lessons || opts.known || opts.active
            if (!valid) {
                window.alert('Turn on an option from lessons/known words/active words.')
                return false
            }
            if (opts.active) {
                const valid3 = opts.activeLow <= opts.activeHigh
                if (!valid3) {
                    window.alert('Low should be lower than High')
                    return false
                }
            }
            return true
        },
        generate() {
            const opts = { ...this.generateOpts, ...this.activeOpts }
            if (!this.verifyGenerate(opts)) return
            this.$emit('generate-set', opts)
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
    },
    data() {
        return {
            generateOpts: {
                lessons: true,
                known: false,
                active: false,
            },
            activeOpts: {
                activeLow: 1,
                activeHigh: 10,
            }
        }
    }
}
</script>
